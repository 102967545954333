.leftAlign {
    text-align: left !important;
}

.rightAlign {
    text-align: right !important;
}

.centerAlign {
    text-align: center !important;
}

.justifyAlign {
    text-align: justify !important;
}

.public-DraftStyleDefault-ltr {
    text-align: inherit;
}