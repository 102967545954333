.App {
  text-align: center;
  background: rgb(219,84,255);
  background: linear-gradient(135deg, rgba(219,84,255,1) 0%, rgba(247,194,16,1) 100%);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

p{
  margin-top:8px;
  margin-bottom:8px;
}

a{
  text-decoration: none;
  color: inherit;
}
h1, h2, h3, h4, h5{
  margin:10px;
}

/** -- Modify Material UI -- **/
.MuiTablePagination-selectRoot{
  margin-right:20px !important;
}

/**documents*/
.docServiceWrapper{
  margin:5px;
}

.docServiceWrapper>div{
  margin:5px;
  padding:5px;
}

.docServiceWrapper>div input{
  padding:5px 10px;
}

.docServiceWrapper>div:last-child button{
  padding:2px;
  min-width: 40px;
}

.servicesHeader{
  background:#f6f9fd;
  margin:0;
  color:#000;
  border-bottom: 1px solid #ccc;
}

.docServiceExpand{
  padding:10px 80px;
}

.docContentWrapper{
  margin-top:130px;
}

.localAppBar{
  top:56px;
}
.MuiPopover-paper  {
  z-index: 20000 !important;
}
.MuiPaper-elevation8{
  z-index: 20000 !important;
}
.MuiPickersBasePicker-container{
  z-index: 20000 !important;
}
.MuiPickersBasePicker-pickerView{
  z-index: 20000 !important;
}
/* .MuiCircularProgress-root {
   background-image: url('./Images/loading.gif');
} */
/** ANIMATIONS */
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}

.link{
  color:#2196f3
}

.loadingWrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.react-resizable-handle{
  display:block;
  border-bottom: 20px solid black;
  border-left: 20px solid transparent;
  position: absolute;
  right: 0px;
  bottom: 0px;
  cursor: crosshair;
}

@media screen and (max-width: 500px) {

  .docServiceWrapper>div{
    margin:0px;
    padding:5px;
  }


  .docServiceExpand{
    padding:10px;
  }

  .docServiceWrapper>div:last-child button{
    padding:2px;
    min-width: 30px;
  }

  .docServiceWrapper{
    margin:0px;
  }
}