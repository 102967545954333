@font-face {
  font-family: Montserrat;
  src: url(Fonts/Montserrat/Montserrat-Regular.ttf);
}

@font-face {
  font-family: Noto_Sans_KR;
  src: url(Fonts/Noto_Sans_KR/NotoSansKR-Regular.otf);
}
@font-face {
  font-family: Roboto;
  src: url(Fonts/Roboto/Roboto-Regular.ttf);
}

body, h1, h2, h3, h4, h5, h6, p, span,div,strong,small {
  margin: 0;
  font-family: Roboto, Montserrat, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
